import styled from 'styled-components';
import { breakpoints, colours } from '../../../components/styled/variables';

const heights = {
  laptop: '800px',
  laptopLarge: '1080px',
};

export const ArrowContainer = styled.div`
  display: none;

  @media (min-width: ${breakpoints.tablet}) {
    display: block;
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 200px;
    max-height: 84px;
  }
`;

export const HorizontalScrollContainer = styled.div`
  position: relative;

  overflow-x: scroll;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    display: none;
  }

  scroll-snap-type: x mandatory;
  scroll-snap-stop: always;
`;

export const HorizontalScroll = styled.div<{ steps: number }>`
  width: calc(${props => props.steps * 100}vw + ${props => props.steps * 12}px);
  display: flex;
  position: relative;
  left: 20px;

  @media (min-width: ${breakpoints.tablet}) {
    left: 30px;
    width: ${props => 610 * props.steps}px;
  }

  @media (min-width: 1200px) {
    left: calc((100vw - 1140px) / 2);
  }
`;

export const ScrollBtn = styled.button<{ direction: 'left' | 'right' }>`
  margin: 0;
  padding: 0;
  appearance: none;
  border: none;
  background: transparent;
  cursor: pointer;
  height: 75px;
  width: 75px;

  position: sticky;
  ${({ direction }) =>
    direction === 'left'
      ? `
      left: 40px;
      transform: scaleX(-1);
    `
      : `
      left: calc(100% - 40px - 71px /* button size */);
    `}
`;

export const StepDescription = styled.div`
  background: ${colours.greyBg};
  padding: 18px 50px 20px;
  min-height: 263px;

  @media (min-width: ${breakpoints.tablet}) and (min-height: ${heights.laptopLarge}) {
    min-height: 255px;
    padding: 40px 50px;
  }
`;

export const Step = styled.div`
  width: calc(100vw - 40px);
  flex-shrink: 0;
  margin-right: 60px;
  margin-bottom: 50px;
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  scroll-snap-align: center;

  &:not(:last-of-type) {
    ::after {
      content: '';
      position: absolute;
      z-index: -1;
      left: calc(100% + 2px);
      bottom: 122px;
      width: 190px;
      height: 6px;

      background-image: linear-gradient(
        to right,
        ${colours.secondary} 49%,
        rgba(255, 255, 255, 0) 49%
      );
      background-size: 16px 6px;
      background-repeat: repeat-x;
    }
  }

  .gatsby-image-wrapper {
    flex-shrink: 0;
    transform: scale(0.5);
    margin-top: -120px;
  }

  ${StepDescription} {
    width: 100%;
    margin-top: -120px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    width: 420px;
    margin-right: 190px;
  }

  @media (min-width: ${breakpoints.tablet}) and (min-height: 700px) {
    .gatsby-image-wrapper {
      transform: scale(0.77);
      margin-top: -70px;
    }

    ${StepDescription} {
      margin-top: -70px;
    }
  }

  @media (min-width: ${breakpoints.tablet}) and (min-height: ${heights.laptop}) {
    .gatsby-image-wrapper {
      transform: scale(0.8);
      margin-top: -60px;
    }

    ${StepDescription} {
      margin-top: -60px;
    }
  }

  @media (min-width: ${breakpoints.tablet}) and (min-height: ${heights.laptopLarge}) {
    transform: none;
    margin-top: 0;
  }
`;

export const StepDotContainer = styled.div`
  width: 100%;
  margin-top: -30px;
  margin-bottom: 20px;
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    display: none;
  }
`;

export const StepDot = styled.span<{ selected?: boolean }>`
  display: inline-block;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;
  width: 5px;
  height: 5px;
  top: -2px;
  background: ${({ selected }) =>
    selected ? colours.accent : colours.codeLightGrey};
  transform: ${({ selected }) => (selected ? 'scale(2)' : '')};
  transition: transform 0.2s, background 0.2s;
`;
