import React, { useState } from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';

import Benefits, { Benefit } from '../../../components/benefits';
import ScrollArrow, {
  Direction,
  useScroll,
} from '../../../components/scroll-arrow';
import { FeatureCTA } from '../../../components/styled';
import { Layout } from '../../../layout';
import { Logos, OverlayTrigger } from '../../../components';

import {
  ArrowContainer,
  BenefitsSection,
  BenefitsWrapper,
  Box,
  CenteredCTA,
  Divider,
  FullWidthSection,
  HeroImg,
  HorizontalScroll,
  HorizontalScrollContainer,
  SectionWithSquareBg,
  Step,
  StepDescription,
  StepDot,
  StepDotContainer,
  Title,
} from '../styled';

import ArrowIcon from '../../products/assets/arrow.svg';
import CheckIcon from '../../../static/icons/tick.svg';

interface ImgType {
  childImageSharp: {
    gatsbyImageData: IGatsbyImageData;
  };
}

export interface UseCaseProps {
  title: string;
  textAboveFold: string;
  datasheetUrl?: string;
  heroImg: ImgType;
  benefits: Benefit[];
  steps: Step[];
  hasLogos?: boolean;
  ctaText?: string;
}

export interface Step {
  img: ImgType;
  title?: string;
  description: string;
}

const UseCase: React.FC<UseCaseProps> = ({
  title,
  textAboveFold,
  datasheetUrl,
  heroImg,
  benefits,
  steps,
  hasLogos,
  ctaText = 'Talk to Sales',
}) => {
  const { scroll, scrollRef } = useScroll({ scrollAmount: 600 });
  const [hidden, setHidden] = useState<'left' | 'right' | null>('left');
  const [currentStep, setCurrentStep] = useState(0);

  function onScroll() {
    if (!scrollRef.current) return;

    const { scrollLeft, scrollWidth } = scrollRef.current;

    if (window.innerWidth < 850)
      setCurrentStep(Math.floor(scrollLeft / window.innerWidth));

    if (scrollLeft < 100) setHidden('left');
    else if (scrollWidth - (scrollLeft + window.innerWidth) < 100)
      setHidden('right');
    else setHidden(null);
  }

  return (
    <Layout title={title}>
      <SectionWithSquareBg maxWidth={1128}>
        <Box width={350}>
          <Title>{title}</Title>
          <p>{textAboveFold}</p>
          {datasheetUrl && (
            <p>
              <a href={`/use-cases/${datasheetUrl}`} download>
                Download the datasheet
              </a>
            </p>
          )}
          <p style={{ marginTop: '20px' }}>
            <FeatureCTA
              as={OverlayTrigger}
              target="contact_sales"
              id={`contactSales-solutions${title.split(' ')[0]}-hero`}
            >
              {ctaText} <ArrowIcon />
            </FeatureCTA>
          </p>
        </Box>
        <HeroImg
          image={heroImg.childImageSharp.gatsbyImageData}
          alt={`${title} image`}
        />
      </SectionWithSquareBg>
      <BenefitsSection>
        <BenefitsWrapper>
          <h2>Benefits:</h2>
          <div>
            <Benefits items={benefits} compact icon={<CheckIcon />} />
          </div>
        </BenefitsWrapper>
        {hasLogos && (
          <>
            <Divider />
            <Logos />
          </>
        )}
      </BenefitsSection>
      <FullWidthSection>
        <h2>What you could build:</h2>
        <HorizontalScrollContainer
          ref={scrollRef}
          onScroll={onScroll}
          data-testid="steps"
        >
          <HorizontalScroll steps={steps.length}>
            <ArrowContainer>
              {['left', 'right'].map(direction => (
                <ScrollArrow
                  key={direction}
                  direction={direction as Direction}
                  onClick={() => scroll(direction)}
                  hidden={direction === hidden}
                />
              ))}
            </ArrowContainer>
            {steps.map(({ img, title: stepTitle, description }, idx) => (
              <Step key={description}>
                <GatsbyImage
                  image={img.childImageSharp.gatsbyImageData}
                  alt={`${stepTitle} image`}
                />
                <StepDescription>
                  <h5>Step {idx + 1}</h5>
                  <h3>{stepTitle}</h3>
                  <p>{description}</p>
                </StepDescription>
              </Step>
            ))}
          </HorizontalScroll>
        </HorizontalScrollContainer>
        <StepDotContainer>
          {steps.map((_, idx) => (
            <StepDot key={idx} selected={currentStep === idx} />
          ))}
        </StepDotContainer>
        <CenteredCTA
          as={OverlayTrigger}
          target="contact_sales"
          id={`contactSales-solutions${title.split(' ')[0]}-steps`}
        >
          {ctaText} <ArrowIcon />
        </CenteredCTA>
      </FullWidthSection>
    </Layout>
  );
};

export default UseCase;
