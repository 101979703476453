import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import UseCase, { UseCaseProps } from './templates/use-case';

const Attribution: React.FC = () => {
  const { heroImg, step1, step2, step3, step4, step5 } =
    useStaticQuery(assetsQuery);

  const benefitsData = [
    {
      title: 'Improve channel assessment',
      description:
        'Attribute spend back to its source to assess the performance of different channels.',
    },
    {
      title: 'Increase visibility',
      description:
        'Identify customer spend across various channels, providing a holistic view and better understanding of spend behaviour.',
    },
    {
      title: 'Drive insights',
      description:
        'Leverage spend data to improve customer experiences across the sales cycle, from initial identification through to post-purchase.',
    },
  ];

  const props: UseCaseProps = {
    title: 'Attribution and Insights',
    textAboveFold:
      'Fidel API’s Select Transactions API provides a holistic picture of customer spend across different channels by connecting payment cards to programmes or apps.',
    datasheetUrl: 'Omnichannel Attribution.pdf',
    heroImg,
    benefits: benefitsData.map(({ title, description }) => ({
      description: (
        <>
          <strong>{title}</strong>
          <br />
          {description}
        </>
      ),
    })),
    steps: [
      {
        img: step1,
        description: 'User securely enrols payment card to app',
      },
      {
        img: step2,
        description:
          'User makes purchase on online platform for advertised service or product',
      },
      {
        img: step3,
        description:
          'Fidel API attributes tokenized customer payment card to a unique ID',
      },
      {
        img: step4,
        description:
          'User makes purchase at participating brand with enrolled payment card',
      },
      {
        img: step5,
        description:
          'Granular payment data is surfaced via Fidel API’s Dashboard in real-time',
      },
    ],
  };

  return <UseCase {...props} />;
};

const assetsQuery = graphql`
  {
    heroImg: file(relativePath: { eq: "use-cases/attribution.png" }) {
      childImageSharp {
        gatsbyImageData(width: 802, placeholder: NONE, layout: FIXED)
      }
    }
    step1: file(relativePath: { eq: "use-cases/attribution/step1.png" }) {
      childImageSharp {
        gatsbyImageData(width: 321, placeholder: NONE, layout: FIXED)
      }
    }
    step2: file(relativePath: { eq: "use-cases/attribution/step2.png" }) {
      childImageSharp {
        gatsbyImageData(width: 734, placeholder: NONE, layout: FIXED)
      }
    }
    step3: file(relativePath: { eq: "use-cases/attribution/step3.png" }) {
      childImageSharp {
        gatsbyImageData(width: 375, placeholder: NONE, layout: FIXED)
      }
    }
    step4: file(relativePath: { eq: "use-cases/attribution/step4.png" }) {
      childImageSharp {
        gatsbyImageData(width: 274, placeholder: NONE, layout: FIXED)
      }
    }
    step5: file(relativePath: { eq: "use-cases/attribution/step5.png" }) {
      childImageSharp {
        gatsbyImageData(width: 736, placeholder: NONE, layout: FIXED)
      }
    }
  }
`;

export default Attribution;
