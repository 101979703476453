import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import { breakpoints, colours } from '../../../components/styled/variables';
import { FeatureCTA, Section } from '../../../components/styled';

export {
  HorizontalScroll,
  HorizontalScrollContainer,
  ScrollBtn,
  Step,
  StepDescription,
  StepDot,
  StepDotContainer,
} from './steps';

export const ArrowContainer = styled.div`
  display: none;

  @media (min-width: ${breakpoints.tablet}) {
    display: block;
    position: absolute;
    z-index: 2;
    width: 100%;
    top: 200px;
  }
`;

const maxSectionWidth = '1360px';

export const BenefitsSection = styled.div`
  background: ${colours.greyBg};
  margin: 20px 20px 50px;
  padding: 20px;
  max-width: ${maxSectionWidth};

  @media (min-width: ${breakpoints.tablet}) {
    padding: 50px 30px 100px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 80px 116px 100px;
  }

  @media (min-width: ${maxSectionWidth}) {
    margin: 0 auto;
  }
`;

export const BenefitsWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  justify-content: flex-start;

  > *:first-child {
    width: 344px;
    max-width: 32%;
    margin-bottom: 0;
  }

  > *:last-child {
    ul {
      max-width: 530px;
    }
  }
`;

export const Title = styled.h2`
  margin: 14px 0 20px;

  @media (min-width: ${breakpoints.tablet}) {
    font-size: 42px;
  }
`;

export const Box = styled.div<{ width: number }>`
  width: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    max-width: ${props => props.width}px;
  }
`;

export const CenteredCTA = styled(FeatureCTA)`
  display: flex;
  margin: 0 auto 80px;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 auto 100px;
  }
`;

export const Divider = styled.hr`
  border-bottom-color: ${colours.border2};
  width: 60%;
  max-width: 500px;
  margin: 20px auto 40px;
  @media (min-width: ${breakpoints.tablet}) {
    margin: 90px auto 60px;
  }
`;

export const FullWidthSection = styled.div`
  width: 100vw;
  margin: 0;
  padding: 0;

  @media (min-width: ${breakpoints.tablet}) {
    width: 100vw;
  }

  h2 {
    padding: 0 20px;

    @media (min-width: ${breakpoints.tablet}) {
      padding: 50px 30px 10px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      max-width: 1200px;
      margin: 0 auto;
    }
  }
`;

export const HeroImg = styled(GatsbyImage).attrs(() => ({
  imgStyle: { objectFit: 'contain' },
}))`
  order: -1;
  flex-shrink: 0;
  max-height: 38vh;
  max-width: calc(100vw - 26px);
  margin-top: -70px;

  position: relative;
  top: -6px;
  left: -7px;

  @media (min-width: ${breakpoints.tablet}) {
    order: 1;
    flex: 1 0 50%;
    align-self: flex-end;
    max-width: 812px;
    max-height: 72vh;
    left: 20px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    left: 60px;
    margin-top: -20px;
  }
`;

export const SectionWithSquareBg = styled(Section)`
  position: relative;
  padding-top: 60px !important;
  display: flex;
  flex-flow: row wrap;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 55px;
  }

  ::after {
    content: '';
    display: block;
    position: absolute;
    z-index: -1;
    top: 0;
    left: 14%;
    width: 72%;
    height: 60vw;
    background: ${colours.greyBg};

    @media (min-width: ${breakpoints.tablet}) {
      height: 100%;
    }
  }
`;
